@import url('https://fonts.googleapis.com/css?family=Press+Start+2P|Roboto&display=swap');

/* Global styles */
:root {
  --warm-dark: #e76f51;
  --warm-light: #f4a261;
  --cool-light: #2a9d8f;
  --cool-dark: #264653;
  --cool-light-text: #002627;
  --cool-light-header: #e0ffff;
  --cool-dark-text: #add8e6;
  --cool-dark-header: #f39c12;
}

.App{
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  background-color: var(--cool-light);
}

body {
  font-size: 1rem;
  line-height: 1.6;
}

h1, h2 {
  font-family: 'Press Start 2P', cursive;
}

a, a:visited {
  text-decoration: none;
  color: inherit;
  margin: 0.5em;
  font-weight: 800;
}

p {
  font-family: 'Roboto', sans-serif;
}

header {
  background-color: var(--warm-light);
  color: var(--cool-dark);
  display: flex;
  text-align: end;
  padding: 1em;
}

main {
  height: 100%;
}

sidebar {
  height: 100%;
  color: var(--cool-dark-text);
  background-color: var(--cool-dark);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 1em;
}

sidebar img {
  width: 60%;
  margin: 0.5em;
}

sidebar ul {
  list-style: none;
}

sidebar h2 {
  color: var(--cool-dark-header);
  margin-top: 1em;
}

.skills {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

article {
  background-color: var(--cool-light);
  height: 55em;
  max-width: 50em;
  display: flex;
  flex-direction: column;
  padding: 0 1em 0 1em;
  margin-bottom: 1em;
  color: var(--cool-light-text);
}

article h1 {
 color: var(--cool-light-header);
 margin-top: 1em;
}

article p, strong {
  padding-top: 1em;
}

@media (min-width: 800px) {
sidebar {
  height: 56em;
  float: left;
  width: 25%;
}
}
